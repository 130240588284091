.header-detail::before {
    content: "";
    background: red;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    left: 80px;
}
.header-detail {
    color: green;
}